import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"

const Sitemap = ({ data, location }) => {
  return (
    <Layout headerType="solid" location={location}>
      <SeoComponent title="Sitemap" description="" />
      <div className="solid-header-page-spacer" />
      <div className="main-sitemap-wrap">
        <div className="sitemap-wrap container py-5">
          <Link
            to="/"
           id="sitemap-list"
            title="Eden Oaks - Custom Woodworking Colorado Springs"
          >
            <i class="fa fa-home mr-2" aria-hidden="true"></i>
            Eden Oaks - Custom Woodworking Colorado Springs
          </Link>
          <br />
          <Link
            to="/about/"
           id="sitemap-list"
            title="Custom Woodworking Shop"
          >
            <i class="fa fa-link mr-2" aria-hidden="true"></i>
            About our Custom Woodworking Shop
          </Link>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <h3 className="py-3"> Colorado Springs</h3>
              <ul id="sitemap-list">
                <li>
                  <Link
                    to="/accent-wall/"
                    title="Reclaimed Barn & Modern Wood Accent Wall Colorado Springs"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Reclaimed Barn & Modern Wood Accent Wall{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/barn-wood/"
                    title="Reclaimed Barn Wood Furniture Colorado Springs"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Reclaimed Barn Wood Furniture{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/bars/"
                    title="Commercial Wood Bar Tops & Installation Colorado Springs"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Commercial Wood Bar Tops & Installation{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/bartops/"
                    title="Custom Wood Bar Tops Colorado Springs"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wood Bar Tops{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/bathroom-vanities/"
                    title="Custom Bathroom Vanities Colorado Springs"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Bathroom Vanities{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/beds/" title="Custom Bed Frames Colorado Springs ">
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Bed Frames{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/cabinetry/"
                    title="Custom Kitchen Cabinets Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Kitchen Cabinets{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/coffee-tables/"
                    title="Custom Farmhouse Coffee Table Colorado Springs"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Farmhouse Coffee Table{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/commercial/"
                    title="Custom Commercial Millwork Colorado Springs"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Commercial Millwork{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/consoles/"
                    title="Reclaimed Wood Entertainment Center Colorado Springs"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Reclaimed Wood Entertainment Center{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/countertops/"
                    title="Butcher Block Table Tops Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Butcher Block Table Tops{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/custom-furniture/"
                    title="Custom & Handcrafted Wood Furniture Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom & Handcrafted Wood Furniture{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/desks/" title="Hand Built Desk Colorado Springs ">
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Hand Built Desk{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/dining-room-tables/"
                    title="Custom Dining Room Tables Colorado Springs"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Dining Room Tables{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/doors/" title="Barn Doors Colorado Springs ">
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Barn Doors{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/end-tables/"
                    title="Custom End Table Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom End Table{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faux-beams/"
                    title="Decorative Faux Wood Beams Colorado Springs"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Decorative Faux Wood Beams{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/finishes-stains/"
                    title="Wood Finishing & Staining Companies Colorado Springs"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Wood Finishing & Staining Companies{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/floatingshelves/"
                    title="Custom Wood Floating Shelves "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wood Floating Shelves{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/gallery/"
                    title="Custom Made Furniture Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Made Furniture{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/islands/"
                    title="Custom Wood Kitchen Islands Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wood Kitchen Islands{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/mantel/"
                    title="Custom Fireplace Mantels Colorado Springs"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Fireplace Mantels{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/materials/"
                    title="Reclaimed Wood Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Reclaimed Wood{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/railings/"
                    title="Custom Wood Handrail Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wood Handrail{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/raw-materials/"
                    title="Wood Planks Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Wood Planks{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/reception/"
                    title="Custom Built Reception & Office Desk Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Built Reception & Office Desk{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/residential/" title="Woodworking Colorado Springs">
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Woodworking{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/signage/"
                    title="Wooden Business Signs Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Wooden Business Signs{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/sliding-barn-doors/"
                    title="Custom Made Sliding Barn Doors Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Made Sliding Barn Doors{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/stains/"
                    title="Custom Wood Stain Colorado Springs"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wood Stain{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/stairtreads/"
                    title="Custom Reclaimed Wood Stair Treads Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Reclaimed Wood Stair Treads{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/table-tops/"
                    title="Handcrafted Wood Tables Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Handcrafted Wood Tables{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/thicker-material/"
                    title="Reclaimed Barn Wood, Wood Wall Planks & Repurposed Lumber Colorado Springs "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Reclaimed Barn Wood, Wood Wall Planks & Repurposed Lumber{" "}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-sm-12">
              <h3 className="py-3">Denver</h3>
              <ul id="sitemap-list">
                <li>
                  <Link to="/denver/" title="Custom Woodworking Denver ">
                    <i class="fa fa-home mr-2" aria-hidden="true"></i>
                    Custom Woodworking{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/accent-wall/"
                    title="Reclaimed Barn & Modern Wood Accent Wall Denver "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Reclaimed Barn & Modern Wood Accent Wall{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/barn-wood/"
                    title="Reclaimed Barn Wood & Accent Wall Materials Denver "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Reclaimed Barn Wood & Accent Wall Materials{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/bars/"
                    title="Commercial Wood Bar Tops & Installation Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Commercial Wood Bar Tops & Installation{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/bartops/"
                    title="Custom Wood Bar Tops Denver "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wood Bar Tops{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/bathroom-vanities/"
                    title="Custom Bathroom Vanities Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Bathroom Vanities{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/denver/beds/" title="Custom Bed Frames Denver">
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Bed Frames{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/cabinetry/"
                    title="Custom Kitchen Cabinets Denver "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Kitchen Cabinets{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/coffee-tables/"
                    title="Custom Made Wood Tables Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Made Wood Tables{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/commercial/"
                    title="Commercial Millwork Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Commercial Millwork{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/consoles/"
                    title="Custom Wood Console Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wood Console{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/countertops/"
                    title="Wood Countertops Denver "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Wood Countertops{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/custom-furniture/"
                    title="Custom Wood Furniture Denver "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wood Furniture{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/denver/desks/" title="Custom Wood Desk Denver ">
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wood Desk{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/dining-room-tables/"
                    title="Custom Wood Dining Tables Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wood Dining Tables{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/denver/doors/" title="Custom Wood Doors Denver">
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wood Doors{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/end-tables/"
                    title="Custom End Table Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom End Table{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/faux-beams/"
                    title="Faux Wood Beams Denver "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Faux Wood Beams{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/finishes-stains/"
                    title="Custom Wood Furniture Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wood Furniture{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/floatingshelves/"
                    title="Custom Floating Shelves Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Floating Shelves{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/gallery/"
                    title="Custom Made Furniture Denver "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Made Furniture{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/islands/"
                    title="Custom Butcher Block Kitchen Island Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Butcher Block Kitchen Island{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/mantel/"
                    title="Reclaimed Wood Fireplace Mantels Denver "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Reclaimed Wood Fireplace Mantels{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/materials/"
                    title="Reclaimed Barn Wood Denver "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Reclaimed Barn Wood{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/railings/"
                    title="Interior Wood Railing Denver "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Interior Wood Railing{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/raw-materials/"
                    title="Reclaimed Wood Furniture Denver "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Reclaimed Wood Furniture{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/reception/"
                    title="Reclaimed Wood Reception Desk Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Reclaimed Wood Reception Desk{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/residential/"
                    title="Custom Desks For Home Office Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Desks For Home Office{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/signage/"
                    title="Custom Wooden Business Signs Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wooden Business Signs{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/sliding-barn-doors/"
                    title="Denver Custom Woodworking "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Woodworking{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/stains/"
                    title="Custom Wood Staining & Refinishing Services Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wood Staining & Refinishing Services{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/stairtreads/"
                    title="Custom Wood Stair Treads Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Wood Stair Treads{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/table-tops/"
                    title="Custom Built Tables Denver "
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Custom Built Tables{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/denver/thicker-material/"
                    title="Reclaimed Wood Products Denver"
                  >
                    <i class="fa fa-link mr-2" aria-hidden="true"></i>
                    Reclaimed Wood Products{" "}
                  </Link>
                </li>
              </ul>
            </div>
            {/*} <div className="col-md-6 col-sm-12">
              <Link
                to="/blog/"
                title="Eden Oaks Posts "
              >
                <h3 className="py-3">
                  Latest Posts{" "}
                  <i
                    class="fa fa-newspaper-o mr-2"
                    style={{ fontSize: "20px" }}
                    aria-hidden="true"
                  ></i>
                </h3>
              </Link>
              <ul classname="sitemap-list">
                <li>
                  <Link
                    to="/benefits-of-wood-stair-railings/"
                    title="The Stairway Railings Design That’s Perfect for Your Style "
                  >
                    <i class="fa fa-newspaper-o mr-2" aria-hidden="true"></i>
                    The Stairway Railings Design That’s Perfect for Your Style{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/choose-the-perfect-stairway-railing-style/"
                    title="5 Things to Keep in Mind When Choosing the Perfect Stair Railing Design with Eden Oaks "
                  >
                    <i class="fa fa-newspaper-o mr-2" aria-hidden="true"></i>5
                    Things to Keep in Mind When Choosing the Perfect Stair
                    Railing Design with Eden Oaks{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/wood-stair-railings-are-great-for-cold-weather/"
                    title="Choosing the Best Stair Railings in Colorado for Cold Weather"
                  >
                    <i class="fa fa-newspaper-o mr-2" aria-hidden="true"></i>
                    Choosing the Best Stair Railings in Colorado for Cold
                    Weather
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
          <div className="mt-5 pt-5">
            <Link
              to="/contact-us/"
              id="sitemap-list"
              title="Custom Woodworking Shops Near Me"
            >
              <i class="fa fa-phone mr-2" aria-hidden="true"></i>
              Contact the Colorado Springs Craftsmen
            </Link>
            <br />
            <Link
              to="/denver/contact-us/"
              id="sitemap-list"
              title="Custom Woodworking Near Me Denver"
            >
              <i class="fa fa-phone mr-2" aria-hidden="true"></i>
              Custom the Denver Craftsmen
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sitemap
